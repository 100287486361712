import { Route } from '@/types/index';
import { t } from '@/plugins/i18n.ts';

const Users = () => import('@/pages/Users/Users.vue');
const AddUserContainer = () => import('@/pages/Users/user/AddUserContainer.vue');
const UserContainer = () => import('@/pages/Users/user/UserContainer.vue');
const AuditLogs = () => import('@/pages/Users/AuditLogs/AuditLogs.vue');
const Alerts = () => import('@/pages/Users/AlertManager/Alerts.vue');
const Rules = () => import('@/pages/Users/AlertManager/Rules.vue');
const AddRuleContainer = () => import('@/pages/Users/AlertManager/AddRuleContainer.vue');
const AlertActions = () => import('@/pages/Users/AlertManager/Actions/Actions.vue');
const AddActionContainer = () => import('@/pages/Users/AlertManager/Actions/AddActionContainer.vue');
const AccountSettings = () => import('@/pages/Users/AccountSettings/AccountSettings.vue');
const Roles = () => import('@/pages/Users/Roles/Roles.vue');
const AddRole = () => import('@/pages/Users/Roles/AddRole/AddRole.vue');
const RoleSettings = () => import('@/pages/Users/Roles/Settings/RoleSettings.vue');

const routes: Route[] = [
  {
    path: '/admin/users/manage',
    name: 'Users',
    component: Users,
    meta: {
      auth: true,
      filterOption: true,
      rootRoute: true,
      tooltipText: t('Users'),
      showBreadcrumbArrow: false,
    },
  },
  {
    path: '/admin/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      auth: true,
      filterOption: true,
      rootRoute: true,
      showBreadcrumbArrow: false,
    },
  },
  {
    path: '/admin/users/add',
    name: 'Add User',
    component: AddUserContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/users/:id',
    name: 'User settings',
    component: UserContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/audit',
    name: 'Audit log',
    component: AuditLogs,
    meta: {
      auth: true,
      filterOption: false,
      rootRoute: true,
      showBreadcrumbArrow: false,
    },
  },
  {
    path: '/admin/alerts',
    name: 'Alert manager alerts',
    component: Alerts,
    meta: {
      auth: true,
      rootRoute: true,
      showBreadcrumbArrow: false,
    },
  },

  {
    path: '/admin/rules',
    name: 'Alert manager rules',
    component: Rules,
    meta: {
      auth: true,
      rootRoute: true,
      showBreadcrumbArrow: false,
    },
  },
  {
    path: '/admin/rules/add',
    name: 'Add rule',
    component: AddRuleContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/rules/:ruleId',
    name: 'Edit rule',
    component: AddRuleContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/alertActions',
    name: 'Alert actions',
    component: AlertActions,
    meta: {
      auth: true,
      rootRoute: true,
      showBreadcrumbArrow: false,
    },
  },
  {
    path: '/admin/alertActions/add',
    name: 'Add action',
    component: AddActionContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/alertActions/:actionId',
    name: 'Edit action',
    component: AddActionContainer,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/account',
    name: 'Account settings',
    component: AccountSettings,
    meta: {
      auth: true,
      rootRoute: false,
      showBreadcrumbArrow: true,
    },
  },
  {
    path: '/admin/roles/add',
    name: 'Add role',
    component: AddRole,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/roles/:id',
    name: 'Role settings',
    component: RoleSettings,
    meta: {
      auth: true,
    },
  },
];

export default routes;
