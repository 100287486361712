import { t } from '@/plugins/i18n';
import _ from 'lodash';
import { TransactionType } from './types';

/**
 * To find a data in POS event response
 *
 * @param type Type of the data
 * @param data POS event data
 * @returns Matching data
 */

export const findPOSEventData = (type: string, data: Array<any>) => {
  return data.find((data: any) => data?.type === type);
};

/**
 * To get POS event transaction type icon
 *
 * @param transactionType Type of transaction
 * @returns Transaction type icon
 */

export const getTransactionTypeIcon = (transactionType: TransactionType) => {
  switch (transactionType) {
    case TransactionType.purchase:
      return { icon: '$icon_check_zero', color: 'positive', text: t('Purchase') };

    case TransactionType.refund:
      return { icon: '$icon_close', color: 'negative', text: t('Refund') };

    case TransactionType.void:
      return { icon: '$icon_sync', color: 'accent', text: t('Exchanged') };

    default:
      return { icon: '', color: '', text: '' };
  }
};

/**
 * Get empty select dropdown option
 *
 * @param text Empty select dropdown option text
 * @returns Empty select dropdown option
 */

export const getEmptySelectOption = (text: string) => ({
  text,
  value: '',
});

/**
 * Get priority range text
 *
 * @param priority Priority value
 * @returns Priority range text
 */

export const getPriorityRangeText = (priority: number) => {
  return _.inRange(priority, 1, 4)
    ? 'low'
    : _.inRange(priority, 4, 7)
    ? 'medium'
    : _.inRange(priority, 7, 10)
    ? 'high'
    : 'alarm';
};

/**
 * To get a string dropdown option
 *
 * @param option Text and value
 * @returns String dropdown option
 */

export const getStringDropdownOption = ({ text, value }: { text: string; value: string }) => ({
  text,
  value,
});

/**
 * To get a translated default label strings
 *
 * @returns Translated default label strings
 */

export const getDefaultLabelStrings = () => {
  return {
    allLabel: t('Select all'),
    searchPlaceholder: t('Search'),
    noResultsLabel: t('No results found'),
  };
};

/**
 * To get a translated location label strings
 *
 * @returns Translated location label strings
 */

export const getSiteLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All sites'),
    itemType: t('sites'),
    noneLabel: t('Select sites'),
  };
};

/**
 * To get a translated camera label strings
 *
 * @returns Translated camera label strings
 */

export const getCameraLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All cameras'),
    itemType: t('cameras'),
    noneLabel: t('Select cameras'),
  };
};

/**
 * To get a translated action label strings
 *
 * @returns Translated action label strings
 */

export const getActionLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All actions'),
    itemType: t('actions'),
    noneLabel: t('Select actions'),
  };
};

/**
 * To get a translated alert type label strings
 *
 * @returns Translated alert type label strings
 */

export const getAlertTypeLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All alerts'),
    itemType: t('alert types'),
    noneLabel: t('Select alert type'),
  };
};

/**
 * To get a translated days label strings
 *
 * @returns Translated days label strings
 */

export const getDaysLabelStrings = () => {
  return {
    allSelectedLabel: t('All days'),
    itemType: t('days'),
    noneLabel: t('None'),
  };
};

/**
 * To get a translated employee label strings
 *
 * @returns Translated employee label strings
 */

export const getEmployeeLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All employees'),
    itemType: t('employees'),
    noneLabel: t('Select employee'),
  };
};

/**
 * To get a translated SKU label strings
 *
 * @returns Translated SKU label strings
 */

export const getSKULabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All SKU Names'),
    itemType: t('SKU Names'),
    noneLabel: t('Select SKU'),
  };
};

/**
 * To get a translated transaction type label strings
 *
 * @returns Translated transaction type label strings
 */

export const getTransactionTypeLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All transaction types'),
    itemType: t('Transaction type'),
    noneLabel: t('Select transaction type'),
  };
};

/**
 * To get priority translations
 *
 * @returns Translated priority
 */

export const getPriorityTranslations = () => {
  return {
    from: t('from'),
    to: t('to'),
    name: t('Priority'),
  };
};

/**
 * To form a query string based on queryParams
 *
 * @param queryParams
 * @returns string
 */

export const formQueryString = (queryParams: object): string => {
  return Object.keys(queryParams)
    .map((key) =>
      Array.isArray(queryParams[key])
        ? queryParams[key].map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
        : `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join('&');
};
